import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios'
import { v4 as uuidv4 } from 'uuid'

import i18n from '@app/i18n'
import {
  AcceptLanguage,
  API_URL,
  AUTH_URL,
  ErrorCode,
  lookupSessionStorage,
  NEW_AUTH_URL,
  SuccessCode
} from '@const/consts'
import store from '@store/configureStore'
import { ICanceler, UtilsActionTypes } from '@store/modules/utils/types'
import { getOrganizationOguid } from '@utils/utils'

import * as jwt from '../utils/jwt'

class Http {
  private isAlreadyFetchingAccessToken: boolean = false
  private isNeedResetError = {}
  private subscribers: any[] = []
  private axiosEvents: ICanceler[] = []
  private readonly axiosEvent: {
    [key: string]: ICanceler
  } = {}

  private readonly axiosInstance: AxiosInstance = axios.create({
    baseURL: sessionStorage.getItem(lookupSessionStorage) ?? API_URL,
    responseType: 'json'
  })

  constructor () {
    this.addInterceptors()
  }

  static updateBaseURL (config: AxiosRequestConfig, addOrgOguid: boolean): void {
    const url = sessionStorage.getItem(lookupSessionStorage) ?? API_URL

    if (config.baseURL === AUTH_URL || config.baseURL === NEW_AUTH_URL) return
    config.baseURL = addOrgOguid ? `${url}orgs/${getOrganizationOguid()}/` : `${url}`
  }

  static setHeaders (config: AxiosRequestConfig): void {
    const { headers = {} } = config

    const tokens = jwt.get()

    const { params = {} } = config
    const { isNeedAuthentication = true } = params

    if (isNeedAuthentication && tokens.accessToken) {
      headers['Access-Token'] = tokens.accessToken
    }

    config.headers = {
      ...headers,
      ['Accept-Language']: AcceptLanguage[i18n.language]
    }
  }

  public async get (
    url: string,
    config: AxiosRequestConfig = {},
    addOrgOguid: boolean = true
  ): Promise<AxiosResponse<any>> {
    Http.setHeaders(config)
    Http.updateBaseURL(config, addOrgOguid)

    const requestId = config.params?.requestId || uuidv4()
    this.setCancelToken(config, requestId)

    return await this.axiosInstance.get(url, config).then(this.filterAxiosEvents(requestId))
  }

  public async patch (
    url: string,
    data: any,
    config: AxiosRequestConfig = {},
    addOrgOguid: boolean = true
  ): Promise<AxiosResponse<any>> {
    Http.setHeaders(config)
    Http.updateBaseURL(config, addOrgOguid)

    const requestId = config.params?.requestId || uuidv4()
    this.setCancelToken(config, requestId)

    return await this.axiosInstance.patch(url, data, config).then(this.filterAxiosEvents(requestId))
  }

  public async post (
    url: string,
    data?: any,
    config: AxiosRequestConfig = {},
    addOrgOguid: boolean = true
  ): Promise<AxiosResponse<any>> {
    Http.setHeaders(config)
    Http.updateBaseURL(config, addOrgOguid)

    const requestId = config.params?.requestId || uuidv4()
    this.setCancelToken(config, requestId)

    return await this.axiosInstance.post(url, data, config).then(this.filterAxiosEvents(requestId))
  }

  public async put (
    url: string,
    data: any,
    config: AxiosRequestConfig = {},
    addOrgOguid: boolean = true
  ): Promise<AxiosResponse<any>> {
    Http.setHeaders(config)
    Http.updateBaseURL(config, addOrgOguid)

    const requestId = config.params?.requestId || uuidv4()
    this.setCancelToken(config, requestId)

    return await this.axiosInstance.put(url, data, config).then(this.filterAxiosEvents(requestId))
  }

  public async delete (
    url: string,
    config: AxiosRequestConfig = {},
    addOrgOguid: boolean = true
  ): Promise<AxiosResponse<any>> {
    Http.setHeaders(config)
    Http.updateBaseURL(config, addOrgOguid)

    const requestId = config.params?.requestId || uuidv4()
    this.setCancelToken(config, requestId)

    return await this.axiosInstance.delete(url, config).then(this.filterAxiosEvents(requestId))
  }

  public removeAxiosEvent(id: string): void {
    const event = this.axiosEvent[id]
    event?.canceler()
    delete this.axiosEvents[id]
  }

  public removeAxiosEvents = (): void => {
    for (let i = this.axiosEvents.length - 1; i >= 0; i--) {
      const event = this.axiosEvents.pop()
      event?.canceler()
    }
  }

  private addInterceptors (): void {
    this.axiosInstance.interceptors.request.use((config) => {
      const { params, url } = config
      if (params?.isNeedResetError && url) {
        this.isNeedResetError[url] = true

        delete config.params.isNeedResetError
      }

      return config
    })

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const { url } = response.config

        if (url) {
          delete this.isNeedResetError[url]
        }

        return response
      },
      (error: any) => {
        const {
          config,
          response
        } = error
        const originalRequest = config
        const status = response?.status

        if (
          (originalRequest.url.includes('signin') || originalRequest.url.includes('password-reset')) &&
          status === ErrorCode.NOT_AUTH
        ) {
          return Promise.reject(error)
        }

        if (originalRequest.url.includes('token-refresh') && status === ErrorCode.NOT_AUTH) {
          jwt.clear()
          document.location.reload()
        }

        if (status === ErrorCode.NOT_AUTH && originalRequest) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            jwt
              .refreshToken()
              .then((resp: any) => {
                if (resp.status === SuccessCode.GET) {
                  this.isAlreadyFetchingAccessToken = false
                  this.onAccessTokenFetched(jwt.get().accessToken)

                  return resp
                }

                return Promise.reject(resp)
              })
              .catch((refreshError: any) => Promise.reject(refreshError))
          }

          return new Promise((resolve: any) => {
            this.subscribers.push(() => {
              originalRequest.headers['Access-Token'] = jwt.get().accessToken
              resolve(axios(originalRequest))
            })
          })
        }

        const { url } = originalRequest

        if (status === ErrorCode.NOT_FOUND && !this.isNeedResetError[url]) {
          store.dispatch({ type: UtilsActionTypes.SET_ERROR404 })
        }

        return Promise.reject(error)
      }
    )
  }

  private setCancelToken (config: AxiosRequestConfig, id: string): void {
    config.cancelToken = new axios.CancelToken((canceler: Canceler) => {
      this.axiosEvent[id] = { canceler, id }

      if (!config.url) return

      if (!config.url.endsWith('statistics') && !config.url.endsWith('user/')) this.axiosEvents.push({ canceler, id })
    })
  }

  private readonly filterAxiosEvents = (id: string) => (resp: AxiosResponse) => {
    this.axiosEvents = this.axiosEvents.filter((event: ICanceler) => event.id !== id)

    return resp
  }

  private onAccessTokenFetched (accessToken: string): void {
    this.subscribers = this.subscribers.filter((callback: any) => callback(accessToken))
  }
}

export default new Http()

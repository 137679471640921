import { AxiosError, AxiosResponse } from 'axios'
import { FormikErrors, FormikTouched } from 'formik'
import { TFunction } from 'i18next'
import { cloneDeep, get, isNull, isString, isBoolean, isNumber } from 'lodash'
import queryString from 'query-string'
import React, { ReactElement, ReactNode, ReactNodeArray } from 'react'
import { match } from 'react-router'
import { LocationDescriptor, LocationState } from 'history'

import i18n from '@app/i18n'

import {
  IconBriefcase,
  IconDropbox,
  IconEditAlt,
  IconError,
  IconFile,
  IconFolderOpen,
  IconPlus,
  IconRecycle,
  IconWaiting,
  ILibOption,
  ILibUserFilter,
  getCurrentTime,
  getFormattedDate, getFormattedTime,
  getSurnameWithInitials,
  getTimeForFilter,
  showBasicNotification as displayBasicNotification,
  showConfirmNotification as displayConfirmNotification,
  showErrorNotification as displayErrorNotification,
  showSuccessNotification as displaySuccessNotification,
  IconSuccess,
  ILibSelectValueType
} from '@infologistics/frontend-libraries'

import { IIconProps } from '@views/archive/components/Archive/Modals/ObjectModal/types'
import { IFilterEtags, IUser as ICachedUser } from '@common/cache/types'
import OrganizationLink from '@common/navigation/components/CustomLinks/OrganizationLink'
import { RouteWithSubRoutes } from '@common/Routing/RouteWithSubRoutes'
import { IRoute } from '@common/Routing/types'
import {
  ALL,
  API_URL,
  CachedFilter,
  CachedFilterInnerKey,
  commentFileNameLength,
  ErrorCode,
  filterPrefixInLocalStorage, Instance, Languages,
  lookupSessionStorage,
  MAX_BADGES_COUNT,
  MINIMUM_ITEMS_COUNT_TO_SHOW_PAGINATION,
  Role,
  RouteName
} from '@const/consts'
import store from '@store/configureStore'
import { AllServiceParams, IBadges, IUserFilter } from '@store/modules/navigation/types'
import { IRequestType } from '@store/modules/requests/types'
import { EmptyString, IAxiosErrorData, ICreator, ISearchParams, Nullable } from '@store/types/commonTypes'

import { IFile } from '@common/ExportModal/types'
import { INavItem, INavItemBadge, menu } from '@const/navSidebar'
import { Menu, ObjectTypes, UserRoles } from '@const/translations'
import { IRecipient, IUser } from '@store/modules/users/types'
import { getOptionFromState } from '@utils/filters'
import { Fields as FieldsObjects } from '@views/objectsList/modals/ExportModal/types'
import { Fields as FieldsOperations } from '@views/orders/components/Order/Plan/modal/OperationsExportModal/types'
import { Fields as FieldsServices } from '@views/administration/components/Services/components/Modals/ServicesExportModal/types'
import { Fields as FieldsDraftObjects } from '@views/requests/components/RequestDraft/components/Pages/ObjectsDraftList/modals/DraftObjectsExportModal/types'
import { IActiveFilterData } from '@views/requests/components/Requests/types'
import {
  IBasicNotificationOptions,
  IConfirmNotificationOptions,
  ISuccessNotificationOptions,
  ITranslatedSelectOptions,
  TTranslationOptionsForDeclension,
  IGetParamsFromSearchOtherProps,
  IFilterFromState
} from './types'
import { IUserState } from '@store/modules/user/types'
import { IGroupMember } from '@store/modules/groups/types'
import { FieldType } from '@store/modules/accounts/types'
import { getPerPageNumber } from './pagination'

export const getOrganizationOguid = (): string => store.getState().organizations.activeOrganization.oguid

export const getUserOguid = (): string => store.getState().user.oguid

export const getURL = (): string => `${API_URL}orgs/${getOrganizationOguid()}`

export const getUserInfo = (): string =>
  getUserFiltersKey({
    organization: getOrganizationOguid(),
    user: getUserOguid()
  })

export const getCreator = (user: ICreator, dateCreated: string): ReactNode => {
  return (
    <div>
      {getFormattedDate(dateCreated)} {getFormattedTime(dateCreated)}{' '}
      <span className='ml-1'>
        {user ? getSurnameWithInitials({ name: user.name, patronymic: user.patronymic, surname: user.surname }) : ''}
        &nbsp;
      </span>
      <span className='text-muted'>{user ? user.position : ''}</span>
    </div>
  )
}

export const getUsersForReactSelect = (arrayData: Array<IUserState | IRecipient>): ILibOption[] =>
  arrayData.map((item: IUserState | IRecipient) => ({
    label: `${getSurnameWithInitials(item)} (${item.email})`,
    value: item.oguid
  }))

export function getRequestTypesForReactSelect(arrayData: any): ILibOption[] {
  return arrayData.map((item: any) => ({
    info: item.request_category,
    label: item.name,
    value: item.oguid
  }))
}

export const getTranslatedSelectOptions = ({ optionNames, nameSpace, t }: ITranslatedSelectOptions): ILibOption[] =>
  optionNames.map((name: string) => ({
    label: t(nameSpace[name]),
    value: name
  }))

export const debounce = (func: any, wait: number): any => {
  let timeout: any = null
  return (...args: any) => {
    const next = (): any => func(...args)
    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}

export const getParamsFromSearch = (search: string, otherProps?: IGetParamsFromSearchOtherProps): ISearchParams => {
  const params: ISearchParams = queryString.parse(search)

  if (otherProps?.page) {
    params.page = otherProps.page
  } else if (!params.page) {
    params.page = 1
  }

  if (otherProps?.perPageKey) {
    params.per_page = getPerPageNumber(otherProps.perPageKey)
  }

  if (otherProps?.filterName) {
    params.filterName = otherProps.filterName
  }

  return params
}

export const handleApiError = (
  err: AxiosError<IAxiosErrorData>,
  isLoading: boolean,
  toggleLoader: () => void,
  getBadges: () => Promise<void> | Promise<AxiosResponse>
): void => {
  if (isLoading) toggleLoader()

  showErrorNotification(err)
  getBadges().catch(showErrorNotification)
}

export const showSuccessNotification = (options?: Nullable<ISuccessNotificationOptions>): void => {
  const { language, getResource } = i18n
  const content = options
    ? options.content ?? getResource(language, 'notification', 'success_content')
    : getResource(language, 'notification', 'success_content')
  const title = options
    ? options.title ?? getResource(language, 'notification', 'success_title')
    : getResource(language, 'notification', 'success_title')

  displaySuccessNotification({ content, title })
}

export const showBasicNotification = (options: IBasicNotificationOptions): void => {
  const { content, title, type, isTimerShow } = options
  const { language, getResource } = i18n

  displayBasicNotification({
    content: content ?? getResource(language, 'notification', 'success_content'),
    title: title ?? getResource(language, 'notification', 'success_title'),
    type,
    isTimerShow
  })
}

export const showErrorNotification = (error: AxiosError<IAxiosErrorData>): void => {
  const { language, getResource } = i18n
  const errorMessage = getResource(language, 'notification', 'error_content')
  const { message, response } = error
  const option = {
    content: '',
    title: ''
  }

  if (!response) return

  if (response?.data) {
    if (response.data.code === ErrorCode.NOT_FOUND) {
      option.content = 'error404'
    } else {
      option.title = `${response.data.code || response.status}`
      option.content = response.data.message || message || errorMessage
    }
  } else {
    option.title = String(getResource(language, 'translation', 'error_title'))
    option.content = errorMessage
  }

  if (option.content !== 'error404') {
    displayErrorNotification(option)
  }
}

export const showConfirmNotification = (options: IConfirmNotificationOptions): void => {
  const { backdrop, cancelText, submitText, content, title, onSubmit, onCancel, theme} = options
  const { language, getResource } = i18n

  displayConfirmNotification({
    backdrop,
    cancelText: cancelText ?? getResource(language, 'common', 'no'),
    content: content ?? getResource(language, 'notification', 'confirm_content'),
    onSubmit,
    onCancel,
    submitText: submitText ?? getResource(language, 'common', 'yes'),
    title: title ?? getResource(language, 'notification', 'confirm_title'),
    theme: theme ?? 'warning'
  })
}

export const addOrgInPath = (routes: IRoute[]): IRoute[] => {
  return routes.reduce((accumulator: IRoute[], currentValue: IRoute) => {
    const newRoute = { ...currentValue }

    const getNewRoutePath = (path: string): string => `${RouteName.ORGANIZATION_URL}${path}`

    if (typeof currentValue.path === 'string') {
      newRoute.path = getNewRoutePath(currentValue.path)
    } else {
      newRoute.path = currentValue.path.map(getNewRoutePath)
    }
    accumulator.push(newRoute)
    return accumulator
  }, [])
}

export const getOrgDataFromMatch = (matchData: match<AllServiceParams>): string => {
  if (matchData?.params?.org) {
    return `/${matchData.params.org}`
  }

  return ''
}

export const createUrl = (urlData: match<AllServiceParams> | string, ...rest: Array<string | number>): string => {
  const org = typeof urlData === 'string' ? `/${urlData}` : getOrgDataFromMatch(urlData)

  return (
    org +
    rest.reduce((accumulator: string, currentValue: string | number) => {
      if (!currentValue) return accumulator

      const newCurrentValue =
        typeof currentValue !== 'number' && !currentValue.includes('/') && accumulator.slice(-1) !== '/'
          ? `/${currentValue}`
          : currentValue
      return `${accumulator}${newCurrentValue}`
    }, '')
  )
}

export const createPath = (org: string, to: LocationDescriptor): LocationDescriptor => {
  const orgOguid = '/' + org

  switch (typeof to) {
    case 'string':
      return orgOguid + to
    case 'object':
      return { ...to, pathname: `${orgOguid}${typeof to.pathname === 'string' ? to.pathname : ''}` }
    default:
      return to
  }
}

export const getRoutesList = (routes: IRoute[]): ReactNodeArray =>
  routes.map((route: IRoute) => {
    const { name, path } = route

    return <RouteWithSubRoutes key={name} {...route} path={path} />
  })

export const getRouteFromMenuItems = (userRole: EmptyString<Role>): string => {
  const menuItems = filterAvailableMenuItems(menu, userRole)
  const menuItem = menuItems[0]
  return menuItem.items ? menuItem.items[0].route : menuItem.route
}

export const compileOrgLink = (alias: Nullable<string>, oguid: string): string => `${alias ?? oguid}`

export const sliceCommentFileName = (filename: string): string => {
  const pointIndex = filename.lastIndexOf('.')
  const fileExtension = pointIndex !== -1 ? filename.slice(pointIndex) : ''
  let slicedName

  if (pointIndex === -1) {
    slicedName = filename.slice(0, commentFileNameLength)
  } else if (pointIndex < commentFileNameLength) {
    slicedName = filename.slice(0, pointIndex)
  } else {
    slicedName = filename.slice(0, commentFileNameLength)
  }

  return `${slicedName}${pointIndex > commentFileNameLength ? '~' : ''}${fileExtension}`
}

export const getNumberOfFirstEntry = (pageIndex: number, itemsPerPage: number): number => {
  // Универсальная формула для получения номера первого значения на странице,
  // например: 1, 11, 21, или 1, 26, 51 и т.д.
  // pageIndex - текущая страница, itemsPerPage - количество позиций чего-либо на странице
  // при pageIndex = 1, itemsPerPage = 10, получаем (1 - 1) * 10 + 1 = 0 + 1 = 1
  // при pageIndex = 2, itemsPerPage = 10, получаем (2 - 1) * 10 + 1 = 10 + 1 = 11 и т.д.
  return (pageIndex - 1) * itemsPerPage + 1
}

export const getEmptySelectMessage = (t: TFunction) => (): string => t('common:no_options')

export const getTranslatedOption = (t: (value: string) => string, value = 'all', namespace = 'common'): ILibOption => ({
  label: t(`${namespace}:${value}`),
  value
})

export const areAllFieldsChecked = (fields: FieldsObjects | FieldsOperations | FieldsServices | FieldsDraftObjects): boolean =>
  Object.keys(fields).filter((field: string) => !fields[field].isChecked).length === 0

export const areAllFieldsUnchecked = (fields: FieldsObjects | FieldsOperations | FieldsServices | FieldsDraftObjects): boolean =>
  Object.keys(fields).filter((field: string) => fields[field].isChecked).length === 0

export const getFilename = (file: IFile): string => {
  const { bookType, name } = file
  return `${name}.${bookType}`
}

export const getOrganizationLink = (path: string, isAdd = false, state?: LocationState): ReactNode => (
  <OrganizationLink to={{ pathname: path, state }}>
    {isAdd ? <IconPlus color='success-500' externalClass='m-1' /> : <IconEditAlt externalClass='m-1' />}
  </OrganizationLink>
)

export const parseSubmenuName = (pathname: string): string => {
  if (pathname.endsWith('/')) return trimSlash(pathname)

  const submenuFirstIndex = pathname.lastIndexOf('/') + 1

  return pathname.slice(submenuFirstIndex, pathname.length)
}

export const parseOrganizationAliasOrOguid = (pathname: string): string => {
  const submenuFirstIndex = pathname.slice(1, pathname.length).indexOf('/') + 1

  return pathname.slice(1, submenuFirstIndex)
}

const trimSlash = (path: string): string => parseSubmenuName(path.slice(0, -1))

export const isListTooShort = (pageIndex: number, length: number): boolean => {
  return pageIndex === 1 && length < MINIMUM_ITEMS_COUNT_TO_SHOW_PAGINATION
}

export const cacheFilters = (user: string, filterName: string, response: AxiosResponse): void => {
  const filters = localStorage.getItem(user)

  const newFilter = {
    [CachedFilterInnerKey.ETAG]: response.headers.etag,
    [CachedFilterInnerKey.DATA]: response.data,
    [CachedFilterInnerKey.TIMESTAMP]: new Date().getTime()
  }

  // searching for cached filter
  if (filters) {
    const parsedFilters = JSON.parse(filters)

    parsedFilters[filterName] = newFilter

    return localStorage.setItem(user, JSON.stringify(parsedFilters))
  }

  // for non-cached filter
  return localStorage.setItem(user, JSON.stringify({ [filterName]: newFilter }))
}

export const getUserFiltersKey = (user: ICachedUser): string =>
  `${filterPrefixInLocalStorage}${user.user}/${user.organization}`

export const getFilterEtags = (filterNames: string[], user: string): IFilterEtags =>
  filterNames.reduce((accumulator, filterName: string) => {
    const filters = localStorage.getItem(user)

    if (filters) {
      const parsedFilters = JSON.parse(filters)

      if (parsedFilters[filterName]) {
        accumulator[filterName] = {
          [CachedFilterInnerKey.ETAG]: parsedFilters[filterName][CachedFilterInnerKey.ETAG]
        }
      }
    }

    return accumulator
  }, {})

export const getFiltersFromCache = (
  filtersKey: string,
  filterName: string,
  isUser?: boolean,
  isUserEmail?: boolean
): ILibOption[] | any[] => {
  const cachedFilterData = window.localStorage.getItem(filtersKey)

  if (!cachedFilterData) {
    return []
  }

  const dataFromCache: IFilterFromState[] = get(
    JSON.parse(cachedFilterData),
    [filterName, CachedFilterInnerKey.DATA],
    []
  )

  return dataFromCache.map(getOptionFromState(isUser, isUserEmail))
}

export const getFullCachedData = (filtersKey: string, filterName: string): Array<object & any> => {
  const cachedFilterData = window.localStorage.getItem(filtersKey)

  if (!cachedFilterData) {
    return []
  }

  return get(JSON.parse(cachedFilterData), [filterName, CachedFilterInnerKey.DATA], [])
}

export const checkFiltersAge = (): void => {
  const localStorageKeys = Object.keys(localStorage)
  const currentTime = getCurrentTime()

  localStorageKeys.forEach((key: string) => {
    const isFilter = key.startsWith(filterPrefixInLocalStorage)

    if (isFilter) {
      const filters = localStorage.getItem(key)

      if (filters) {
        const parsedFilters = JSON.parse(filters)
        const filterNames = Object.keys(parsedFilters)

        const newFilters = cloneDeep(parsedFilters)

        filterNames.forEach((filterName: string) => {
          const timestamp = parsedFilters[filterName][CachedFilterInnerKey.TIMESTAMP]
          const newTimeForFilter = getTimeForFilter(timestamp)
          const oldFilter = newTimeForFilter < currentTime

          if (oldFilter) {
            delete newFilters[filterName]
          }
        })

        const newFiltersKeysLength = Object.keys(newFilters).length
        const needToRemoveFilters = newFiltersKeysLength === 0
        const needToRewriteFilters = newFiltersKeysLength > 0 && filterNames.length !== newFiltersKeysLength

        if (needToRemoveFilters) {
          return localStorage.removeItem(key)
        }

        if (needToRewriteFilters) {
          return localStorage.setItem(key, JSON.stringify(newFilters))
        }
      }
    }
  })
}

export const hasError = (name: string, errors?: FormikErrors<any>, touched?: FormikTouched<any>): boolean =>
  !!(errors?.[name] && touched?.[name])

/**
 * Для работы переводов функция должна быть в месте вызова обернута в t()
 */
export const getErrorText = (name: string, errors?: FormikErrors<any>): string =>
  errors?.hasOwnProperty(name) ? String(errors[name]) : ''

export const getRequestType = (filterKey: string, requestTypeOguid: string): (object & IRequestType) | undefined => {
  const requestTypes: Array<object & IRequestType> = getFullCachedData(filterKey, CachedFilter.REQUEST_TYPES)

  return requestTypes.find((type: object & IRequestType) => type.oguid === requestTypeOguid)
}

export const getStatusIcon = (status: string, classes?: string): ReactElement => {
  /* error статус нужен для операций */
  const statusIcon = {
    by_fact: <IconSuccess color='warning-500' classes={classes} />,
    confirm: <IconSuccess color='success-500' classes={classes} />,
    declared: <IconWaiting classes={classes} />,
    error: <IconError />
  }

  return statusIcon[status]
}

export const getFormattedBooleanValue = (t: TFunction, value: boolean): string =>
  value ? t('common:yes') : t('common:no')

export const compileAddress = (city: Nullable<string>, address: Nullable<string>): string => {
  if (city && address?.includes(`${city}`)) return address

  const addressArray = [city, address].filter(Boolean)

  return addressArray.length ? addressArray.join(', ') : ''
}

/**
 * Функция для преобразования числового значения бейджа в строковое
 * с учётом ограничения на максимально допустимое значение MAX_BADGES_COUNT
 * @badges - бейджи из стора
 * @badge - информация о бейдже в сторе (путь до бейджа, имя бейджа)
 * @return Строковое значение бейджа (например, '5', '10', '99+')
 */
export const getBadgeValue = (badges: IBadges, badge: INavItemBadge): string => {
  // Числовое значение бейджа (например, 5, 10, 100)
  const count = badges[badge.path][badge.name]

  return count <= MAX_BADGES_COUNT ? count.toString() : `${MAX_BADGES_COUNT}+`
}

export const filterAvailableMenuItems = (menuItems: INavItem[], role: EmptyString<Role>, hasReports?: boolean): INavItem[] => {
  return menuItems.filter((menuItem: INavItem) => {
    const isRoleCorrect = !!role && menuItem.roles.includes(role)
    const isMenuItemReport = menuItem.title === Menu.reports

    if (!isRoleCorrect) return false

    if (isMenuItemReport && !hasReports) return false

    if (menuItem.items?.length) {
      menuItem.items = filterAvailableMenuItems(menuItem.items, role)
    }

    return true
  })
}

export const modifyRoutes = (privateRoutes: IRoute[], role: EmptyString<Role>): IRoute[] => {
  const newRoutes = filterAvailableRoutes(cloneDeep(privateRoutes), role)

  return addOrgInPath(newRoutes)
}

const filterAvailableRoutes = (routes: IRoute[], role: EmptyString<Role>): IRoute[] => {
  return routes.filter((route: IRoute) => !!role && route.roles && route.roles.includes(role))
}

export const compileUserFilters = (userFilters: IUserFilter[], route: string): ILibUserFilter[] => {
  const filters = userFilters.reduce((accumulator: ILibUserFilter[], currentValue: IUserFilter) => {
    if (currentValue.route === route) {
      const newFilter: ILibUserFilter = {
        crossButton: true,
        oguid: currentValue.oguid,
        translation: currentValue.title
      }
      accumulator.push(newFilter)
    }

    return accumulator
  }, [])

  const { language, getResource } = i18n
  const allTasks = getResource(language, 'orders', 'all_tasks')
  const allRequests = getResource(language, 'requests', 'all_requests')

  filters.unshift({
    oguid: ALL,
    translation: route === RouteName.ORDERS ? allTasks : allRequests
  })

  return filters
}

export const getActiveFilter = ({ filterParams, userFilters, activeFilter }: IActiveFilterData): string => {
  // у пользователя нет пользовательских фильтров
  if (userFilters.length === 0) {
    return ALL
  }

  // в search нет фильтров
  if (!filterParams) return ALL

  const filterWithoutQuestionMark = filterParams.replace('?', '')
  const parsedFilter = queryString.parse(filterWithoutQuestionMark)

  if ('page' in parsedFilter) delete parsedFilter.page
  if ('per_page' in parsedFilter) delete parsedFilter.per_page

  const modifiedFilter = queryString.stringify(parsedFilter)

  if (!modifiedFilter) return ALL

  // поиск среди пользовательских фильтров по совпадению params с search
  const filterFromSearch = userFilters.filter((userFilter: IUserFilter) => userFilter.params === modifiedFilter)

  if (filterFromSearch.length > 0) {
    // если пользователь создал два фильтра с одинаковым search,
    // возвращаем тот, который совпадает с activeFilter
    const activeFilterFromIdenticalFilters = filterFromSearch.find(
      (filter: IUserFilter) => filter.oguid === activeFilter
    )
    if (activeFilterFromIdenticalFilters) return activeFilterFromIdenticalFilters.oguid

    // если нет совпадений с activeFilter, возвращаем первый найденный
    return filterFromSearch[0].oguid
  }

  if (activeFilter) return activeFilter

  return ALL
}

// проверяем, что опция существует и опция не массив
export const selectChangeCheck = (cb: any) => (option: ILibSelectValueType): void => {
  if (option && !Array.isArray(option)) {
    cb(option)
  }

  if (isNull(option)) {
    const clearOption: ILibSelectValueType = {
      label: '',
      value: '',
    }

    cb(clearOption)
  }
}

export const getTypeIcon = ({ type, ...rest }: IIconProps): ReactNode => {
  const typeToIcon = {
    box: <IconDropbox {...rest} />,
    document: <IconFile {...rest} />,
    folder: <IconFolderOpen {...rest} />,
    object_transport: <IconRecycle {...rest} />,
    supply: <IconBriefcase {...rest} />
  }

  return typeToIcon[type]
}

export const getObjectTypeIcon = (
  objectType: string,
  t: TFunction,
  classes?: string,
  color: string = 'gray-500'
): ReactNode => {
  const iconProps = {
    classes,
    color,
    hint: t(ObjectTypes[objectType]),
    type: objectType
  }

  return getTypeIcon(iconProps)
}

export const getObjectColor = (seal1: string | null, seal2: string | null): string => {
  if (seal1 && seal2) return 'primary-500'
  if (seal1 ?? seal2) return 'danger-500'
  return 'gray-500'
}

export const getObjectPopoverTitle = (seal1: string | null, seal2: string | null, t: TFunction): string => {
  if (seal1 && seal2) return t('common:popover.sealed')
  return t('common:popover.unsealed')
}

export const getFullUserName = ({ name, patronymic, surname }: IUser | IRecipient | IGroupMember): string =>
  `${surname || ''} ${name || ''} ${patronymic ?? ''}`

export const getUsersWithRole = (users: Array<IUser | IRecipient>, t: TFunction): ILibOption[] => {
  return users.map((user: IUser | IRecipient) => {
    const { oguid, role } = user
    const roleText = role ? `(${t(UserRoles[role])})` : ''

    return {
      label: `${getFullUserName(user)} ${roleText}`,
      value: oguid
    }
  })
}

// Возвращает Options со склонением, что бы использовать его в t(), если оно найдено в соответствующем "справочном" объекте
export const TranslationOptionsForDeclension: TTranslationOptionsForDeclension = (declensions, field) =>
  declensions[field] ? { context: declensions[field] } : undefined

export const decimalPlacesSlice = (value: string, numberOfDecimalPlaces: number): string => {
  const index = value.indexOf('.')

  if (index !== -1) {
    if (numberOfDecimalPlaces === 0) {
      return value.slice(0, index)
    }

    return value.slice(0, index + numberOfDecimalPlaces + 1)
  }

  return value
}

export const getFieldValue = (
  field_type: FieldType,
  value: Nullable<boolean | string | number>
): Nullable<boolean | string | number> => {
  if (isNull(value)) return null

  switch (field_type) {
    case FieldType.DATE:
      return isNumber(value) ? value : null
    case FieldType.DATETIME:
      return isNumber(value) ? value : null
    case FieldType.DICTIONARY:
      return isString(value) ? value : null
    case FieldType.BOOLEAN:
      return isBoolean(value) ? value : null
    case FieldType.STRING:
      return isString(value) ? value : null
    case FieldType.NUMBER:
      return isNumber(value) ? value : null
    default:
      return null
  }
}

export const getBaseUrl = (): string => sessionStorage.getItem(lookupSessionStorage) ?? API_URL

export const getInstance = (passedInstance?: string): Instance => {
  const instance = passedInstance ?? getInstance(store.getState().utils.instance)

  if (instance === Instance.BR) return Instance.BR
  if (instance === Instance.RU) return Instance.RU
  if (instance === Instance.ZA) return Instance.ZA

  return Instance.GLOBAL
}

export const getLanguageFromInstance = (locale: string, instance: Instance | string): string => {
  const isRussiaInstance = instance === Instance.RU
  const isRussiaLocale = locale === Languages.RU

  if (!locale) {
    switch (instance) {
      case Instance.RU:
        return Languages.RU
      case Instance.BR:
        return Languages.PT
      default:
        return Languages.EN
    }
  }

  if (isRussiaLocale && isRussiaInstance) return Languages.RU

  if (!isRussiaLocale) return locale

  return Languages.EN
}

export const getPreferredLanguages = (instance: Instance | string, locale: string): string[] => {
  if (instance === Instance.RU || locale === Languages.RU) return ['ru', 'en', 'pt']

  return ['en', 'pt']
}

export const getLanguageName = (locale: string): string => {
  const localeName = locale.substring(0, 2)

  return Object.values(Languages).includes(localeName) ? localeName : Languages.EN
}


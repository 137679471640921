import { ILibLabelProps } from '@infologistics/frontend-libraries'

import { FieldType } from '@store/modules/accounts/types'

export const isDevelopment =
  process.env.REACT_APP_API_ENV === 'development'

export const AUTH_URL =
  isDevelopment
    ? 'https://api-rmk-backend-dev.dev.info-logistics.eu/'
    : `https://api.${window.location.hostname}/`

export const API_URL =
  isDevelopment
    ? 'https://api-rmk-backend-dev.dev.info-logistics.eu/v1/'
    : `https://api.${window.location.hostname}/v1/`

export const NEW_AUTH_URL =
  isDevelopment
    ? 'https://auth-rmk-backend-dev.dev.info-logistics.eu/v1/'
    : `https://auth.${window.location.hostname.replace('rm-keeper.','')}/v1/`

export enum Instance {
  GLOBAL = 'global',
  RU = 'ru',
  BR = 'com.br',
  ZA = 'co.za'
}

export const Languages = {
  EN: 'en',
  RU: 'ru',
  PT: 'pt'
}

export const AcceptLanguage = {
  en: 'en-US',
  ru: 'ru-RU',
  pt: 'pt-BR'
}

export enum lookupLocalStorage {
  INSTANCE = 'instance',
  LANGUAGE = 'i18nextLng'
}

export const lookupSessionStorage = 'baseUrl'

export const COPYRIGHT = 'ILS LLC. All rights reserved.'

export const ITEMS_PER_PAGE_FOR_CART = 10
export const PER_PAGE_ITEMS_DEFAULT = 25
export const ANIMATION_COUNTER = 5
export const MINIMUM_ITEMS_COUNT_TO_SHOW_PAGINATION = 10
export const MAX_BADGES_COUNT = 99
export const NAV_ANIMATION_DELAY = 100

export const NewComment = {
  MAX_LENGTH: 500,
  ROWS_COUNT: 7
}

export const COMMENTS_PER_PAGE = 100

export const APP_TYPE = 'RMK'

export enum Role {
  ADMIN = 'ROLE_PROVIDER_ADMIN',
  CLIENT = 'ROLE_CLIENT',
  DISPATCHER = 'ROLE_PROVIDER_DISPATCHER',
  EMPLOYEE = 'ROLE_PROVIDER_EMPLOYEE',
  TSD = 'ROLE_PROVIDER_OPERATOR_TSD'
}

export const AllRoles = [Role.CLIENT, Role.ADMIN, Role.TSD, Role.EMPLOYEE, Role.DISPATCHER]

export const RolesWithoutTSD = [Role.CLIENT, Role.ADMIN, Role.EMPLOYEE, Role.DISPATCHER]

export const RolesWithoutClient = [Role.ADMIN, Role.TSD, Role.EMPLOYEE, Role.DISPATCHER]

export const RolesWithoutClientAndTSD = [Role.ADMIN, Role.EMPLOYEE, Role.DISPATCHER]

export const RolesAdminAndDispatcher = [Role.ADMIN, Role.DISPATCHER]

export const SuccessCode = {
  DELETE: [200, 202, 204],
  GET: 200,
  PATCH: 200,
  POST: [201, 204],
  PUT: [200, 201, 204]
}

export const ResponseCode = {
  DEL: 204,
  GET: 200,
  NO_CONTENT: 204,
  POST: 201,
  PUT: 201,
  ERROR: 400
}

export const ErrorCode = {
  NOT_AUTH: 401,
  NOT_FOUND: 404
}

export const ETagCode = {
  NOT_MODIFIED: 304
}

export const initialPagination = {
  itemsPerPage: 10,
  nextPage: null,
  pageIndex: 1,
  prevPage: null,
  total: 0
}

export const PhoneLength = {
  MAX: 25,
  MIN: 10
}

export const InputLength = {
  LARGE: 30,
  MAX: 50,
  MEDIUM: 15,
  MIN: 3
}

export const KeyCode = {
  ENTER: 13,
  ESC: 27,
  SPACE: 32
}

export const RequestTypes = {
  assigned_to: 'in_progress_orders_assigned_to',
  new: 'new',
  no_order: 'in_progress_orders_is_null',
  pending: 'new',
  started: 'in_progress_orders_started'
}

export const OrderTypes = {
  assigned_to: 'orders_assigned_to',
  new: 'orders_new',
  started: 'orders_started'
}

export enum OrderStatus {
  ASSIGNED = 'assigned_to',
  COMPLETED = 'completed',
  DECLINED = 'declined',
  NEW = 'new',
  PROGRESS = 'started'
}

export const orderStatuses = [
  OrderStatus.NEW,
  OrderStatus.ASSIGNED,
  OrderStatus.PROGRESS,
  OrderStatus.COMPLETED,
  OrderStatus.DECLINED
]

export const ALL = 'all'

export const ordersStatuses = [ALL, ...orderStatuses]

export enum RequestStatus {
  DRAFT = 'draft',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  DECLINED = 'declined',
  COMPLETED = 'completed'
}

export const requestStatuses = [
  RequestStatus.DRAFT,
  RequestStatus.NEW,
  RequestStatus.IN_PROGRESS,
  RequestStatus.DECLINED,
  RequestStatus.COMPLETED
]

export const Mode = {
  ADMINISTRATOR: 'administrator',
  ARCHIVE: 'my_archive',
  MANAGEMENT: 'management'
}

export const MIN_LENGTH_FOR_SUBACCOUNT_NAME = 2

export const TerminalName = {
  MAX_LENGTH: 30,
  MIN_LENGTH: 3
}

export const OrganizationLength = {
  alias: { MAX_LENGTH: 16, MIN_LENGTH: 6 },
  name: { MAX_LENGTH: 50, MIN_LENGTH: 3 }
}

export const PasswordLength = {
  MAX: 20,
  MIN: 6
}

export const UserStatusToColor: { [key in UserStatusesEnum]: { label: ILibLabelProps['status'] } } = {
  active: { label: 'success' },
  blocked: { label: 'danger' },
  deactivated: { label: 'warning' }
}

export enum UserStatusesEnum {
  Active = 'active',
  Deactivated = 'deactivated',
  Blocked = 'blocked'
}

export const userStatuses = ['active', 'deactivated', 'blocked']

export const CurrencyReg = /^[0-9]+([.,])?([0-9]{1,2})?$/

export const Url = {
  ADMINISTRATION_ACCOUNT_EDIT: '/account',
  ADMINISTRATION_GROUP_EDIT: '/group',
  ADMINISTRATION_TERMINAL_EDIT: '/terminal',
  ADMINISTRATION_USER_ADD: '/users/add',
  REQUEST: '/request'
}

export const RequestStatusToColor: { [key in RequestStatus]: { label: ILibLabelProps['status'] } } = {
  completed: { label: 'success' },
  declined: { label: 'default' },
  draft: { label: 'default' },
  in_progress: { label: 'primary' },
  new: { label: 'danger' }
}

export const RequestStatusColor = {
  completed: 'success-500',
  declined: 'muted-900',
  draft: 'muted-500',
  in_progress: 'primary-500',
  new: 'danger-500'
}

export const OrderStatusToColor: { [key in OrderStatus]: { label: ILibLabelProps['status'] } } = {
  assigned_to: { label: 'warning' },
  completed: { label: 'success' },
  declined: { label: 'default' },
  new: { label: 'danger' },
  started: { label: 'primary' }
}

export enum Operation {
  OUT = 'out',
  PICK_UP = 'pick_up',
  PUT = 'put',
  ATTACH = 'attach',
  ATTACH_TO_TR = 'attach_to_tr',
  DETACH = 'detach',
  DESTROY = 'DESTROY',
  PERMANENT_OUT = 'PermanentOUT',
  PUT_SEAL = 'PUT_SEAL',
  REMOVE_SEAL = 'REMOVE_SEAL',
  ADD_OPERATION = 'add_operation'
}

export const operationTypes = [
  Operation.OUT,
  Operation.PICK_UP,
  Operation.PUT,
  Operation.ATTACH,
  Operation.ATTACH_TO_TR,
  Operation.DETACH,
  Operation.DESTROY,
  Operation.PERMANENT_OUT,
  Operation.PUT_SEAL,
  Operation.REMOVE_SEAL
]

export const destinationValues = {
  DESTROY: {
    destination: 'DESTROY',
    isDestinationDisabled: true
  },
  PUT_SEAL: {
    destination: '',
    isDestinationDisabled: false
  },
  REMOVE_SEAL: {
    destination: '',
    isDestinationDisabled: false
  },
  PermanentOUT: {
    destination: 'PermanentOUT',
    isDestinationDisabled: true
  },
  attach: {
    destination: '',
    isDestinationDisabled: false
  },
  attach_to_tr: {
    destination: '',
    isDestinationDisabled: false
  },
  detach: {
    destination: 'operator',
    isDestinationDisabled: true
  },
  out: {
    destination: 'out',
    isDestinationDisabled: true
  },
  pick_up: {
    destination: 'operator',
    isDestinationDisabled: true
  },
  put: {
    destination: '',
    isDestinationDisabled: false
  }
}

export enum ObjectType {
  BOX = 'box',
  DOCUMENT = 'document',
  FOLDER = 'folder',
  OBJECT_TRANSPORT = 'object_transport',
  SUPPLY = 'supply'
}

export enum State {
  IN = 'in',
  OUT = 'out',
  DESTROYED = 'destroyed',
  CLOSED = 'closed',
}

export enum ObjectTypeForCustomFields {
  BOX = 'box',
  DOCUMENT = 'document',
  FOLDER = 'folder'
}

export const objectTypes = [
  ObjectType.BOX,
  ObjectType.DOCUMENT,
  ObjectType.FOLDER,
  ObjectType.OBJECT_TRANSPORT,
  ObjectType.SUPPLY
]

export const states = [
  State.IN,
  State.OUT,
  State.DESTROYED,
  State.CLOSED,
]

export const objectTypeAll = 'all'

export const objectTypesExtendedWithAllType = [objectTypeAll, ...objectTypes]

export const barcodesSetting = {
  PREFIX: 5,
  LENGTH: 12,
  LENGTH_PREFIX: 5,
  MAX_LENGTH_DIGITAL_PART: 12,
  MIN_LENGTH_DIGITAL_PART: 1
}

export const InputLengths = {
  BARCODE: 150,
  DESTINATION: 32,
  OBJECTS: 2000,
  ORDER_TYPE: 32,
  OTHERS: 1000,
  REQUEST_TYPE: 50
}

export const excelRequestObjectsParseTypes = {
  child: 'add_child',
  object: 'add_object'
}

export const PathServiceName = {
  ADD: '/add',
  ASTERISK_SYMBOL: '/*',
  EDIT: '/edit',
  ID: '/:id',
  PARENT: '/:parent',
  TYPE: '/:type'
}

export const RouteName = {
  ACCOUNTS: '/accounts',
  ADD_ORG: '/add-org',
  ADMINISTRATION: '/administration',
  ARCHIVE: '/archive',
  BARCODES: '/barcodes',
  DEFAULT: '/',
  FORGOT: '/forgot-password',
  GROUPS: '/groups',
  OPERATIONS: '/operations',
  ORDERS: '/orders',
  ORGANIZATION_URL: '/:org',
  PAGE404: '/404',
  PROFILE: '/profile',
  PROPS: '/props',
  REPORTS: '/reports',
  REQUEST: '/request',
  REQUEST_DRAFT: '/request_draft',
  REQUESTS: '/requests',
  REQUEST_TYPES: '/request-types',
  RESET: '/password-reset',
  SIGN_IN: '/login',
  SIGN_UP: '/register',
  TASK_TYPES: '/order-types',
  TERMINALS: '/terminals',
  USERS: '/users',
  SERVICES: '/services',
  CONTRACTORS: '/contractors'
}

export const Section = {
  ACCOUNTS: `${RouteName.ADMINISTRATION}${RouteName.ACCOUNTS}`,
  ACCOUNTS_ADD: `${RouteName.ADMINISTRATION}${RouteName.ACCOUNTS}${PathServiceName.ADD}`,
  GROUPS: `${RouteName.ADMINISTRATION}${RouteName.GROUPS}`,
  GROUPS_ADD: `${RouteName.ADMINISTRATION}${RouteName.GROUPS}${PathServiceName.ADD}`,
  PROPS: `${RouteName.ADMINISTRATION}${RouteName.PROPS}`,
  REQUEST_TYPES: `${RouteName.ADMINISTRATION}${RouteName.REQUEST_TYPES}`,
  TASK_TYPES: `${RouteName.ADMINISTRATION}${RouteName.TASK_TYPES}`,
  TASK_TYPES_ADD: `${RouteName.ADMINISTRATION}${RouteName.TASK_TYPES}${PathServiceName.ADD}`,
  TERMINALS: `${RouteName.ADMINISTRATION}${RouteName.TERMINALS}`,
  TERMINALS_ADD: `${RouteName.ADMINISTRATION}${RouteName.TERMINALS}${PathServiceName.ADD}`,
  USERS: `${RouteName.ADMINISTRATION}${RouteName.USERS}`,
  USERS_ADD: `${RouteName.ADMINISTRATION}${RouteName.USERS}${PathServiceName.ADD}`,
  SERVICES: `${RouteName.ADMINISTRATION}${RouteName.SERVICES}`,
  CONTRACTORS: `${RouteName.ADMINISTRATION}${RouteName.CONTRACTORS}`
}

export const Path = {
  ACCOUNTS_EDIT: `${Section.ACCOUNTS}${PathServiceName.ID}`,
  ARCHIVE: `${RouteName.ARCHIVE}${PathServiceName.TYPE}`,
  GROUPS_EDIT: `${RouteName.ADMINISTRATION}${RouteName.GROUPS}${PathServiceName.ID}`,
  ORDERS_EDIT: `${RouteName.ORDERS}${PathServiceName.ID}`,
  ORGANIZATION_ROUTE: `${RouteName.ORGANIZATION_URL}${PathServiceName.ASTERISK_SYMBOL}`,
  REQUEST_DRAFT: `${RouteName.REQUEST_DRAFT}`,
  REQUESTS_EDIT: `${RouteName.REQUESTS}${PathServiceName.ID}`,
  TASK_TYPES_EDIT: `${Section.TASK_TYPES}${PathServiceName.ID}`,
  TERMINALS_EDIT: `${Section.TERMINALS}${PathServiceName.ID}`,
  USERS_EDIT: `${Section.USERS}${PathServiceName.ID}`
}

export const MenuItems = {
  ORDERS: 'orders',
  REQUESTS: 'requests'
}

export const INVALID = 'invalid'

export const RequestType = {
  DELIVERY: 'delivery',
  DIGITAL_DELIVERY: 'digit_delivery',
  PICKUP: 'pickup'
}

export const TIMEOUT = 4000

export const Field = {
  ACCOUNT: 'account',
  ADDRESS: 'address',
  CITY: 'city',
  CONTACT_EMAIL: 'contact_email',
  CONTACT_NAME: 'contact_name',
  CONTACT_PHONE: 'contact_phone',
  INITIATOR: 'initiator',
  PASS_IS_NEEDED: 'pass_is_needed',
  PROCESS_IS_NEEDED: 'process_is_needed',
  SUB_ACCOUNT: 'sub_account',
  TERMINAL: 'terminal'
}

export const BADGES_UPDATE_INTERVAL = 60000

export const ArchivePath = {
  ALL: 'all',
  BOX: 'box',
  DOCUMENT: 'document',
  FOLDER: 'folder'
}

export const phoneRegex = new RegExp(
  `^(\\s*)?(\\+)?([- _():=+]?\\d[- _():=+]?){${PhoneLength.MIN},${PhoneLength.MAX}}(\\s*)?$`
)
export const loginRegex = /^\S+$/
export const initialRegex = /^(\d|[а-яА-ЯёЁ]|(?![×÷])[A-Za-zÀ-ÿ]|\s|-|'|"|`|\.)+$/
export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const aliasRegex = /^[a-zA-Z0-9_-]{6,16}$/

export const TagNames = {
  INPUT: 'INPUT'
}

export const Key = {
  ENTER: 'Enter',
  ESCAPE: 'Escape'
}

export const Alias = {
  Length: {
    MAX: 16,
    MIN: 6
  }
}

export interface IValue {
  value: string
  hasError: boolean
}

export const OrderStatuses = {
  DRAFT: 'draft',
  NEW: 'new'
}

export const commentFileNameLength = 12

export enum OrderOuterFieldsLength {
  OUTER_COMPANY = 100,
  OUTER_ORDER_NUMBER = 30
}

export enum DateFormat {
  DATE_FULL_YEAR = 'dd.MM.yyyy',
  DATETIME = 'dd.MM.yyyy HH:mm',
  DATETIME_FULL_YEAR = 'dd.MM.yyyy HH:mm',
  TIME_ONLY = 'HH:mm'
}

export const ObjectsFilterName = {
  BARCODE_IN: 'barcode.in'
}

export enum AccomplishStatus {
  ERROR = 'error',
  QUEUED = 'queued',
  STARTED = 'started',
  SUCCESS = 'success'
}

export enum FilterName {
  ACCOUNT_OGUID_EQ = 'account_oguid.eq',
  SUB_ACCOUNT_OGUID_EQ = 'sub_account_oguid.eq',
  TERMINAL_OGUID_EQ = 'terminal_oguid.eq',
  IS_CLIENTS_ONLY_FILTER = 'is_clients_only_filter',
  ORDER_OGUID_EQ = 'order_oguid.eq',
  IS_SHOW_BLOCKED_FILTER = 'is_show_blocked_filter'
}

/**
 * Используется в ситуациях, когда не нужно, чтобы ошибка в запросе приводила к редиректу на 404-ю страницу.
 * Например, при загрузке данных для опций в селекте
 */
export const ResetError = { isNeedResetError: true }

// ToDo собрать остальные id модалок здесь
export enum ModalId {
  ACCOUNT_SERVICE = 'accountService',
  ADD_OBJECT = 'addObject',
  ADD_OBJECTS = 'addObjects',
  ADD_FOLDERS = 'addFolders',
  ADDING_MEMBER = 'addingMember',
  ADDITIONAL_FIELD = 'additionalField',
  ASSIGN_TO_USER = 'assignToUser',
  BARCODE = 'addBarcode',
  BARCODES = 'barcodes',
  BLANK_OBJECT = 'itemModal',
  CART = 'cart',
  CONTRACTOR = 'contractor',
  NEW_ORDER_NOTIFICATION = 'newOrderNotification',
  NEW_OPERATION = 'newOperation',
  NEW_REPORT = 'newReport',
  NEW_TERMINAL = 'newTerminal',
  OBJECT_MODAL = 'objectModal',
  OBJECTS_EXPORT = 'objectsExport',
  OPERATIONS_EXPORT = 'operationsExport',
  ORDER_MODAL = 'orderModal',
  ORDER_OPERATIONS = 'orderOperations',
  ORDER_TYPE_SERVICE = 'orderTypeService',
  PLAN_OF_OPERATION = 'planOfOperation',
  RECALCULATION = 'recalculation',
  REQUEST_MODAL = 'requestModal',
  REQUEST_DECLINE_REASON = 'requestDeclineReason',
  REQUEST_OR_ORDER_SERVICE = 'requestOrOrderService',
  REQUEST_TYPE = 'requestType',
  SERVICE = 'service',
  SERVICES_EXPORT = 'servicesExport',
  SUB_ACCOUNT = 'subAccount',
  UPDATE_OBJECTS = 'objectsUpdate',
  USER_FILTERS = 'userFilters',
  USER_SELECT = 'userSelect'
}

export enum CommonPropName {
  ORDER = 'order',
  REQUEST = 'request'
}

export const LOADER_TIMEOUT = 300

export enum TooltipPosition {
  BOTTOM = 'bottom',
  TOP = 'top'
}

export const PAGE_DEFAULT_VALUE = 1

export const LAST_PAGE = 'last'

export enum CachedFilter {
  ACCOUNTS = 'accounts',
  TERMINALS = 'terminals',
  REQUEST_TYPES = 'request_types',
  ORDER_TYPES = 'order_types',
  USERS = 'users',
  GROUPS = 'groups'
}

export enum LocalStorage {
  ARCHIVE_FILTERS = 'archive_filters',
  ORDERS_FILTERS = 'orders_filters',
  REQUESTS_FILTERS = 'requests_filters',
  OPERATIONS_FILTERS = 'operations_filters',
  BARCODES_FILTERS = 'barcodes_filters',
  USERS_FILTERS = 'users_filters',
  TERMINAL_FILTERS = 'terminal_filters'
}

export enum FilterMode {
  /** Дефолтный режим - основные фильтры отображаются всегда, дополнительные - только использованные */
  DEFAULT = 'default',
  /** Альтернативный режим - основные фильтры тоже показываются только использованные */
  /** Сейчас нигде не используется */
  SHORT = 'short'
}

export const FILTER_MODE: FilterMode = FilterMode.DEFAULT

export const allDataParams = { page: -1 }

export const paramsForFilters = { ...ResetError, page: -1 }

export enum CachedFilterInnerKey {
  DATA = 'data',
  TIMESTAMP = 'timestamp',
  ETAG = 'etag'
}

export const filterPrefixInLocalStorage = 'filters/'

export enum RequestField {
  REQUEST_TYPE = 'request_type',
  CONTACT_EMAIL = 'contact_email',
  CONTACT_PHONE = 'contact_phone',
  CONTACT_NAME = 'contact_name',
  PASS_IS_NEEDED = 'pass_is_needed',
  CITY = 'city',
  ADDRESS = 'address',
  NOTES = 'notes',
  PROCESS_IS_NEEDED = 'process_is_needed',
  INITIATOR = 'initiator',
  IS_DIGITAL = 'is_digital',
  TERMINAL = 'terminal',
  ACCOUNT = 'account',
  SUB_ACCOUNT = 'sub_account',
  SUB_ACCOUNT_COUNT = 'sub_account_count',
  LAST_STEP = 'last_step'
}

// Ключи, которые в IRequest(@store/modules/requests/types) имеют в значении объект с name и oguid
export type RequestFieldHavingObject =
  | RequestField.REQUEST_TYPE
  | RequestField.INITIATOR
  | RequestField.TERMINAL
  | RequestField.ACCOUNT
  | RequestField.SUB_ACCOUNT

export enum NewRequestObjects {
  ALL = 'total',
  BOX = 'box',
  FOLDER = 'folder',
  DOCUMENT = 'document'
}

export enum MouseEventType {
  AUXCLICK = 'auxclick',
  CLICK = 'click'
}

export enum BadgesPathName {
  ORDER = 'order',
  REQUEST = 'request'
}

export enum RequestBadgesNames {
  DRAFT = 'draft',
  IN_PROGRESS_ORDERS_ASSIGNED_TO = 'in_progress_orders_assigned_to',
  IN_PROGRESS_ORDERS_COMPLETED = 'in_progress_orders_completed',
  IN_PROGRESS_ORDERS_DECLINED = 'in_progress_orders_declined',
  IN_PROGRESS_ORDERS_IS_NULL = 'in_progress_orders_is_null',
  IN_PROGRESS_ORDERS_NEW = 'in_progress_orders_new',
  IN_PROGRESS_ORDERS_STARTED = 'in_progress_orders_started',
  NEW = 'new'
}

export enum OrderBadgesKeys {
  ORDERS_ASSIGNED_TO = 'orders_assigned_to',
  ORDERS_ASSIGNED_TO_ME = 'orders_assigned_to_me',
  ORDERS_NEW = 'orders_new',
  ORDERS_STARTED = 'orders_started'
}

export enum TaskTypeField {
  CODE = 'code',
  NAME = 'name',
  IS_OUTER = 'is_outer',
  IS_AUTOCLOSE_REQUEST = 'is_autoclose_request',
  ALLOWED_OBJECT_TYPE = 'allowed_object_type',
  IS_PAYABLE = 'is_payable',
  ALLOWED_OPERATIONS_TYPE = 'allowed_operations_type',
  IS_OBJECTS_REQUIRED = 'is_objects_required'
}

export const NS = [
  '404',
  'accounts',
  'archive',
  'auth',
  'barcodes',
  'cart',
  'common',
  'currency',
  'export_modal',
  'filters',
  'groups',
  'language',
  'notification',
  'object',
  'operations',
  'order_type',
  'orders',
  'organization',
  'pagination',
  'recipient',
  'reports',
  'request_type',
  'requests',
  'services',
  'terminals',
  'user'
]

export enum TranslationsDeclension {
  MASCULINE = 'masculine',
  FEMININE = 'feminine',
  NEUTER = 'neuter'
}

export const labelClasses = 'text-muted font-xs mb-0'

export const unitPriceDiscount = {
  description: '',
  unit_price: '',
  quantity_from: '',
  quantity_to: ''
}

export enum UrlKey {
  REQUESTS = 'requests',
  ORDERS = 'orders'
}

export enum RequestTab {
  OBJECTS = 'objects',
  ORDERS = 'orders',
  HISTORY = 'history',
  INFORMATION = 'information',
  SERVICES = 'services'
}

export const DecimalPlace = {
  int: 0,
  money: 2,
  number: 6
}

export const ACCOUNT_CUSTOM_FIELDS_FILTER = 'object_type.eq'

export const CustomFieldDefaultValueDependingOnType = {
  [FieldType.DATE]: null,
  [FieldType.STRING]: '',
  [FieldType.BOOLEAN]: false,
  [FieldType.DATETIME]: null,
  [FieldType.NUMBER]: null,
  [FieldType.DICTIONARY]: ''
}

export enum InputType {
  CHECKBOX = 'checkbox'
}

export enum BooleanValue {
  FALSE = 'false',
  TRUE = 'true'
}

export const BooleanValues = [BooleanValue.TRUE, BooleanValue.FALSE]

export const DAYS_IN_YEAR = 365

export enum ObjectsToCart {
  SELECTED = 'selected',
  SELECTED_NESTED = 'selected_nested',
  NESTED = 'nested',
  PARENTAL = 'parental'
}

export enum DriverType {
  EXECUTOR = 'executor',
  ANOTHER = 'another'
}

export enum ForwarderType {
  RMK = 'rmk',
  ANOTHER = 'another',
  WITHOUT = 'without'
}

export const ActiveItems = {
  ADD_DOCUMENTS: 'addDocuments',
  ADD_FOLDERS: 'addFolders',
  ADD_OBJECTS: 'addObjects',
  LIST: 'list',
}

export const TableNames = {
  ALL: 'all',
  ERRORS: 'errors'
}

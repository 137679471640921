import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { INewReport, IReport, IReportType } from '@store/modules/reports/types'
import { ISuccessfulRequest } from '@store/types/commonTypes'

class ReportsService extends AbstractHttpService {
  private readonly url = urls.reports

  public async getReportTypes (): Promise<AxiosResponse<IReportType[]>> {
    const url = this.url.list.report_types

    return await this._http.get(url)
  }

  public async getReports (oguid: string): Promise<AxiosResponse<IReport[]>> {
    const url = this.url.list.reports.replace('{{ report_type_oguid }}', oguid)

    return await this._http.get(url)
  }

  public async postReport (report: INewReport): Promise<AxiosResponse<IReport[]>> {
    const url = this.url.single.new_report

    const date = new Date();
    const offset = date.getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset)/60);
    const minutes = Math.abs(offset)%60;
    const sign = Math.sign(offset)===-1?"+":"-";

    const offsetStr = `${sign}${(hours<10)?"0":""}${hours}:${(minutes<10)?"0":""}${minutes}`;

    const config : AxiosRequestConfig = {
      headers: {
        "utc_offset": offsetStr
      }
    }

    return await this._http.post(url, report, config)
  }

  public async deleteReport (oguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.delete.replace('{{ report_oguid }}', oguid)

    return await this._http.delete(url)
  }

  public async getReportFile (oguid: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
    const url = urls.reports.single.file.replace('{{ report_oguid }}', oguid)

    return await this._http.get(url, config)
  }
}

export default new ReportsService()

import {
  AllRoles,
  ArchivePath,
  BadgesPathName,
  OrderBadgesKeys,
  RequestBadgesNames,
  Role,
  RolesWithoutClient,
  RolesWithoutClientAndTSD,
  RolesWithoutTSD,
  RouteName,
  Section
} from '@const/consts'
import { Archive, Menu } from '@const/translations'

import {
  ILibIcon,
  Theme
} from '@infologistics/frontend-libraries'
import { IconList } from '@infologistics/frontend-libraries/dist/components/Icon/types'

const uuidv4 = require('uuid/v4')

const iconProps: ILibIcon = {
  externalClass: 'mr-2'
}

export interface INavItemBadge {
  name: string
  theme?: Theme
  path: string
}

export interface IRoleModel {
  roles: Role[]
}

export interface INavItem extends IRoleModel {
  badge?: INavItemBadge
  icon?: IconList
  iconProps?: ILibIcon
  items?: INavItem[]
  route: string
  title: string
  uuid: string
}

const archive: INavItem[] = [
  {
    roles: RolesWithoutTSD,
    route: `${RouteName.ARCHIVE}/${ArchivePath.ALL}`,
    title: Archive.all,
    uuid: uuidv4()
  },
  {
    roles: RolesWithoutTSD,
    route: `${RouteName.ARCHIVE}/${ArchivePath.BOX}`,
    title: Archive.boxes,
    uuid: uuidv4()
  },
  {
    roles: RolesWithoutTSD,
    route: `${RouteName.ARCHIVE}/${ArchivePath.FOLDER}`,
    title: Archive.folders,
    uuid: uuidv4()
  },
  {
    roles: RolesWithoutTSD,
    route: `${RouteName.ARCHIVE}/${ArchivePath.DOCUMENT}`,
    title: Archive.documents,
    uuid: uuidv4()
  }
]

const administration: INavItem[] = [
  {
    roles: [Role.ADMIN],
    route: Section.USERS,
    title: Menu.users,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.GROUPS,
    title: Menu.groups,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.ACCOUNTS,
    title: Menu.accounts,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.TERMINALS,
    title: Menu.terminals,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.PROPS,
    title: Menu.props,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.TASK_TYPES,
    title: Menu.order_types,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.REQUEST_TYPES,
    title: Menu.request_types,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.SERVICES,
    title: Menu.services,
    uuid: uuidv4()
  },
  {
    roles: [Role.ADMIN],
    route: Section.CONTRACTORS,
    title: Menu.contractors,
    uuid: uuidv4()
  }
]

export const menu: INavItem[] = [
  {
    badge: {
      name: RequestBadgesNames.NEW,
      path: BadgesPathName.REQUEST,
      theme: 'danger'
    },
    icon: 'IconTasks',
    iconProps: iconProps,
    roles: RolesWithoutTSD,
    route: RouteName.REQUESTS,
    title: Menu.requests,
    uuid: uuidv4()
  },
  {
    badge: {
      name: OrderBadgesKeys.ORDERS_ASSIGNED_TO_ME,
      path: BadgesPathName.ORDER,
      theme: 'warning'
    },
    icon: 'IconFileSolid',
    iconProps: iconProps,
    roles: RolesWithoutClient,
    route: RouteName.ORDERS,
    title: Menu.orders,
    uuid: uuidv4()
  },
  {
    icon: 'IconArchive',
    iconProps: iconProps,
    items: archive,
    roles: RolesWithoutTSD,
    route: RouteName.ARCHIVE,
    title: Menu.archive,
    uuid: uuidv4()
  },
  {
    icon: 'IconArrows',
    iconProps: iconProps,
    roles: RolesWithoutClientAndTSD,
    route: RouteName.OPERATIONS,
    title: Menu.operations,
    uuid: uuidv4()
  },
  {
    icon: 'IconBarcode',
    iconProps: iconProps,
    roles: RolesWithoutClientAndTSD,
    route: RouteName.BARCODES,
    title: Menu.barcodes,
    uuid: uuidv4()
  },
  {
    icon: 'IconFile',
    iconProps: iconProps,
    roles: AllRoles,
    route: RouteName.REPORTS,
    title: Menu.reports,
    uuid: uuidv4()
  },
  {
    icon: 'IconCog',
    iconProps: iconProps,
    items: administration,
    roles: [Role.ADMIN],
    route: RouteName.ADMINISTRATION,
    title: Menu.administration,
    uuid: uuidv4()
  }
]

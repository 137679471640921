import { Role } from '@app/const/consts'
import { Actions as ActionsUser, UserActionTypes } from '@store/modules/user/types'
import { Actions, IOrganization, IOrganizationsState, OrganizationsActionTypes } from './types'

const initialState: IOrganizationsState = {
  activeOrganization: {
    alias: '',
    name: '',
    oguid: '',
    user_role: '',
    isClient: false
  },
  organizationsList: []
}

export function organizationsReducer (
  state: IOrganizationsState = initialState,
  action: Actions | ActionsUser
): IOrganizationsState {
  let id = -1

  switch (action.type) {
    case UserActionTypes.SET_ORGANIZATIONS:
      return { ...state, organizationsList: action.payload }

    case OrganizationsActionTypes.ADD_ORGANIZATION:
      return { ...state, organizationsList: [...state.organizationsList, action.payload] }

    case OrganizationsActionTypes.UPDATE_ORGANIZATION: {
      const organizationsList = state.organizationsList.slice()
      let activeOrganization = { ...state.activeOrganization }

      id = organizationsList.findIndex((item: IOrganization) => item.oguid === action.payload.oguid)

      if (id !== -1) {
        organizationsList[id] = { ...organizationsList[id], ...action.payload }
        activeOrganization = { ...organizationsList[id] }
      }
      return {
        activeOrganization,
        organizationsList
      }
    }

    case OrganizationsActionTypes.CHANGE_ORGANIZATION: {
      return { ...state, activeOrganization: { ...action.payload, isClient: action.payload.user_role === Role.CLIENT } }
    }

    default:
      return state
  }
}
